export default {
    'center.t1': '个人信息',
    'center.t2': '学习',
    'center.t3': '我的课程',
    'center.t4': '做作业',
    'center.t5': '作业收藏夹',
    'center.t6': '教务',
    'center.t7': '自助调课转班',
    'center.t8': '考试查分',
    'center.t9': '姓名',
    'center.t10': '请输入姓名',
    'center.t11': '原密码',
    'center.t12': '请输入原密码',
    'center.t13': '性别',
    'center.t14': '男',
    'center.t15': '女',
    'center.t16': '学号',
    'center.t17': '新密码',
    'center.t18': '新密码8-12位',
    'center.t19': '身份证',
    'center.t20': '请输入身份证号',
    'center.t21': '选择文件',
    'center.t22': '电话',
    'center.t23': '请输入电话号码',
    'center.t24': '学校',
    'center.t25': '请选择(支持模糊，例如…',
    'center.t26': '年级',
    'center.t27': '父亲职业',
    'center.t28': '录入父亲职业信息',
    'center.t29': '母亲职业',
    'center.t30': '录入母亲职业信息',
    'center.t31': '地址',
    'center.t32': '订阅天天练未完成提醒',
    'center.t33': '编辑',
    'center.t34': '保存',
    'center.t35': '姓名不能为空',
    'center.t36': '密码长度不对',
    'center.t37': '密码不能为空',
    'center.t38': '年级不能为空',
    'center.t39': '任课老师',
    'center.t40': '上课地点',
    'center.t41': '上课时间',
    'center.t42': '课程价格',
    'center.t43': '查看课表',
    'center.t44': '倒计时',
    'center.t45': '取消订单',
    'center.t46': '去上课',
    'center.t47': '查看交互式课件',
    'center.t48': '查看课件',
    'center.t49': '做课后测',
    'center.t50': '请阅读并签字《方田培训服务协议》',
    'center.t51': '课次',
    'center.t52': '课次状态',
    'center.t53': '类型',
    'center.t54': '操作',
    'center.t55': '已下课',
    'center.t56': '未上课',
    'center.t57': '线下',
    'center.t58': '线上',
    'center.t59': '回放',
    'center.t60': '暂无数据',
    'center.t61': '待缴费',
    'center.t62': '正在上',
    'center.t63': '即将上',
    'center.t64': '已上完',
    'center.t65': '退课',
    'center.t66': '待缴费',
    'center.t67': '正在上',
    'center.t68': '即将上',
    'center.t69': '已上完',
    'center.t70': '已关闭',
    'center.t71': '电话号码',
    'center.t72': '考试时间',
    'center.t73': '学科',
    'center.t74': '成绩',
    'center.t75': '准考证',
    'center.t76': '年级排名',
    'center.t77': '推荐班型',
    'center.t78': '订阅天天练未完成提醒',
    'center.t79': '包含解析',
    'center.t80': '不包含解析',
    'center.t81': '提交答案',
    'center.t82': '加入打印',
    'center.t83': '今日天天练',
    'center.t84': '往期天天练',
    'center.t85': '笔记本',
    'center.t86': '错题本',
    'center.t87': '学员信息',
    'center.t88': '级别',
    'center.t89': '课程流程',
    'center.t90': '选择待调课/转班课程',
    'center.t91': '选择业务类型',
    'center.t92': '临时调课',
    'center.t93': '永久转班',
    'center.t94': '选择目标班级',
    'center.t95': '业务类型',
    'center.t96': '搜索',
    'center.t97': '无学员',
    'center.t98': '保密',
    'center.t99': '提示',
    'center.t100': '当前课次为线下课',
    'center.t101': '当前暂无可上课次',
    'center.t102': '基础资料',
    'center.t103': '切换学员',
    'center.t104': '修改订单',
    'center.t105': '确认退课吗',
    'center.t106': '请联系客服进行退款退课操作！',
    'center.t107': '审核不通过',
    'center.t108': '待审核',
}
