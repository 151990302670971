import Vue from 'vue'
import { Progress, Divider, Select, Modal, Spin, Dropdown , Menu, Icon, Button, Avatar, Upload} from 'ant-design-vue';
import App from './App.vue'
import storage from 'store'
import router from './router'
import store from './store'
import i18n from './locales'


import './assets/css/reset.css'
import './assets/css/common.css'
import './assets/font/iconfont.css'
import './assets/font/font.css'
import 'ant-design-vue/dist/antd.css'

import { Caxios } from './utils/axios'

import { getClientWidth } from './utils/common'

async function getBranchschools () {
    const result: any = await Caxios.post({ url: '/api/official/user/branchSchoolList' })
    store.dispatch('common/setBranchschools', result.data)
}
getBranchschools()

getClientWidth()
window.onresize = () => {
    getClientWidth()
}

// 初始化年级、学科
async function initSelectPanel () {
    const result: any = await Caxios.get({ url: '/api/datadict/multiply/SUBJECT,GRADE' })
    store.dispatch('course/setAjaxData', result)
}

// initSelectPanel()

let singapore_personal_Data: any = localStorage.getItem('singapore_personal_Data')
if(singapore_personal_Data) {
    store.dispatch('personal/setPersonalData', JSON.parse(singapore_personal_Data))
}


/* 全局引入过滤器 */
import * as filters from './utils/filters'
Object.keys(filters).forEach((key: string): void => {
    let _filters: any = filters
    Vue.filter(key, _filters[key])
})

// message弹窗
import message from './components/message/message'
Vue.use(message);
Vue.use(Progress);
Vue.use(Divider);
Vue.use(Select);
Vue.use(Modal);
Vue.use(Spin);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Avatar);
Vue.use(Upload);

// 确认弹窗
// import confirm from './components/confirm/index'
// Vue.use(confirm);

import theConfirm from './components/confirm/index.ts'
Vue.prototype.$confirm = theConfirm;
Vue.prototype.$storage = storage
// 总线
Vue.prototype.$eventBus = new Vue()


Vue.config.productionTip = false

const vueApp = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')

export default vueApp
